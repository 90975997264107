import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Offcanvas, Nav, Navbar, Button } from "react-bootstrap";
import MenuIcon from "../assets/images/menu-icon.png";
import TelegramIcon from "../assets/images/telegramIcon.svg";
import WhatsappIcon from "../assets/images/whatsappIcon.svg";
import NotificationIcon from "../assets/images/notificationIcon.svg";
import UserIcon from "../assets/images/user-icon.svg";
import BalanceIcon from "../assets/images/balance-icon.png";
import CashierIconNew from "../assets/images/Plus.png";
import WalletIcon from "../assets/images/WalletIcon.png";
import BetIcon from "../assets/images/BetIcon.png";
import AcStatement from "../assets/images/AcStatement.png";
import AcSettings from "../assets/images/AcSettings.svg";
import MoonLight from "../assets/images/moon.svg";
import SunLight from "../assets/images/sunny.svg";

import { FaRegMoneyBillAlt } from "react-icons/fa";
import { MdOutlineVerified } from "react-icons/md";
import { BiSupport } from "react-icons/bi";
import { AiOutlineFileDone } from "react-icons/ai";
import LogoutIconImg from "../assets/images/logout.png";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/auth/actions";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.webp";
import BonusImg from "../assets/images/bonus.png";
import refferal from "../assets/images/refferal.png";
import MobLeftbar from "./MobLeftbar";
import { AiOutlineSearch } from "react-icons/ai";
import GamesSearchModal from "../views/components/GamesSearchModal";
import numberWithCommas from "../utils/numbersWithComma";

import DepositIcon from "../assets/images/icons/payment.png";
import WithdrawIcon from "../assets/images/icons/cash-withdrawal.png";

import HomeIcon from "../assets/images/icons/header/home.png";
import HomeIcon3D from "../assets/images/icons/header/home-3d.png";
import ExchangeIcon from "../assets/images/icons/header/exchange.png";
import ExchangeIcon3D from "../assets/images/icons/header/exchange-3d.png";
import SportsbookIcon from "../assets/images/icons/header/sportsbook.png";
import SportsbookIcon3D from "../assets/images/icons/header/sportsbook-3d.png";
import LiveDealerIcon from "../assets/images/icons/header/liveDealer.png";
import LiveDealerIcon3D from "../assets/images/icons/header/liveDealer-3d.png";
import GameShowIcon from "../assets/images/icons/header/gameShow.png";
import GameShowIcon3D from "../assets/images/icons/header/gameShow-3d.png";
import AviatorIcon from "../assets/images/icons/header/aviator-icon.png";
import AviatorIcon3D from "../assets/images/icons/header/aviator-3d.png";
import MinesIcon from "../assets/images/icons/header/mines.png";
import MinesIcon3D from "../assets/images/icons/header/Mines3d.png";
import AeSexyIcon from "../assets/images/icons/header/aesexy.png";
import AeSexyIcon3D from "../assets/images/icons/header/aesexy-3d.png";

import { getNotifications } from "../redux/app/actions";
import { AiOutlineClose } from "react-icons/ai";

const HeaderAfterLogin = () => {
  const { user, balance, wallet } = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(!show1);
  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);
  const [isClassAdded, setIsClassAdded] = useState(false);
  const [websiteNotifications, setWebsiteNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(true);
  const [showToggle, setShowToggle] = useState(true);
  useEffect(() => {
    const isClassAddedLocalStorage = localStorage.getItem("isClassAdded");
    if (isClassAddedLocalStorage) {
      setIsClassAdded(true);
      document.body.classList.add("light-theme");
    }
  }, []);
  const toggleClass = () => {
    if (isClassAdded) {
      setIsClassAdded(false);
      localStorage.removeItem("isClassAdded");
      document.body.classList.remove("light-theme");
    } else {
      setIsClassAdded(true);
      localStorage.setItem("isClassAdded", true);
      document.body.classList.add("light-theme");
    }
  };

  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const headerPages = [
    "home",
    "spmsports",
    "betby",
    "live-dealer",
    "evolution",
    "aviator",
    "mines",
    "aesexy",
    "/",
    "sportsbetby",
  ];

  useEffect(() => {
    if (websiteNotifications.length && showNotifications) {
      document.body.classList.add("marquee");
    } else {
      document.body.classList.remove("marquee");
    }
  }, [websiteNotifications, showNotifications]);

  useEffect(() => {
    const matchedValue = headerPages.find((f) => {
      if (location?.pathname == "/spmsports") {
        return f === location?.pathname.split("/")?.[1];
      } else {
        return location?.pathname.includes(f);
      }
    });
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  const loadNotifications = async () => {
    // get notifications
    const notifications = await getNotifications();
    if (notifications && notifications?.data?.data) {
      setShowNotifications(true);
      setWebsiteNotifications(notifications?.data?.data);
    }
  };

  useEffect(() => {
    setShowToggle(window.screen.width > 992);
    loadNotifications();
    return () => {};
  }, []);
  return (
    <header className="header afterlogin">
      {(websiteNotifications.length && showNotifications && (
        <div className="marqu">
          <marquee>
            {websiteNotifications.map((notf, idx) => (
              <label>{notf?.content}</label>
            ))}
          </marquee>
          <span onClick={() => setShowNotifications(false)}>
            <AiOutlineClose />
          </span>
        </div>
      )) ||
        null}
      <div className="topHead">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <Button
                  variant="primary menu-btn"
                  onClick={handleShow1}
                  className="d-block d-md-none"
                >
                  <img src={MenuIcon} alt="User Menu Icon" width={25} />
                </Button>

                <div className="logo">
                  <a href="/sports">
                    <img src={appDetails?.LOGO_URL} alt="Logo" />
                  </a>
                </div>

                {/* <div className="header-left mx-auto d-none d-xl-block"> */}
                <div className="header-left mx-auto d-none">
                  <Navbar.Toggle
                    aria-controls="basic-navbar-nav"
                    className="d-none"
                  />
                  <Navbar.Collapse id="basic-navbar-nav" className="d-block">
                    <Nav className="me-auto">
                      <Nav.Link
                        href="/sports"
                        className={
                          activePage == "sports" || activePage === "/"
                            ? "active"
                            : ""
                        }
                      >
                        Home
                      </Nav.Link>
                      <Nav.Link
                        href="/sportsbook/Inplay"
                        className={
                          activePage == "sportsbook/Inplay" ? "active" : ""
                        }
                      >
                        Exchange
                      </Nav.Link>
                      <Nav.Link
                        href="/betby"
                        className={activePage == "sportsbetby" ? "active" : ""}
                      >
                        Sportsbook
                      </Nav.Link>
                      <Nav.Link
                        href="/live-dealer"
                        className={activePage == "live-dealer" ? "active" : ""}
                      >
                        Live Dealers
                      </Nav.Link>
                      <Nav.Link
                        href="/casino/evolution"
                        className={activePage == "evolution" ? "active" : ""}
                      >
                        Game Shows
                      </Nav.Link>
                      <Nav.Link
                        href="/casino/spribe/aviator"
                        className={activePage == "aviator" ? "active" : ""}
                      >
                        Aviator
                      </Nav.Link>
                      <Nav.Link
                        href="/casino/spribe/mines"
                        className={activePage == "mines" ? "active" : ""}
                      >
                        Mines
                      </Nav.Link>
                    </Nav>
                    <Nav.Link
                      href="/casino/aesexy"
                      className={activePage == "aesexy" ? "active" : ""}
                    >
                      Ae-Sexy
                    </Nav.Link>
                  </Navbar.Collapse>
                </div>

                <div className="headerRight ms-auto ms-xl-0">
                  <ul>
                    <li className="balance">
                      {" "}
                      <div className="balance-amount" href="/cashier">
                        {/* <img src={BalanceIcon} alt="Balance Icon" width={24} /> */}
                        <a href="/withdraw" className="WithDrawHeader">
                          W
                        </a>
                        <span> Balance: {wallet?.balance}</span>
                        <span>Cash: {numberWithCommas(wallet?.cash)}</span>
                        <span>Bonus: {numberWithCommas(wallet?.coins)}</span>

                        <a href="/gatewaylist" className="WithDrawHeader">
                          D
                        </a>
                        {/* <a href="/gatewaylist" className="deposit-header">
                        <img
                          src={CashierIconNew}
                          alt="Balance Icon"
                          width={24}
                        />
                      </a> */}
                      </div>
                    </li>
                    <li className="d-none">
                      <GamesSearchModal />
                    </li>
                    <li className="head-social">
                      <a
                        href={
                          appDetails?.TELEGRAM != ""
                            ? appDetails?.TELEGRAM
                            : "javascript:void(0)"
                        }
                        target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                      >
                        <img src={TelegramIcon} alt="telegram" />
                      </a>
                    </li>

                    <li className="d-none">
                      <a
                        className="head-social"
                        href={
                          appDetails?.WHATS_APP_URL != ""
                            ? `${appDetails?.WHATS_APP_URL}`
                            : "javascript:void(0)"
                        }
                        target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                      >
                        <img src={WhatsappIcon} alt="whatsapp" />
                      </a>
                    </li>
                    {/* <div className="searchbar">
                    <AiOutlineSearch />
                  </div> */}
                  </ul>

                  <Button
                    variant="primary menu-btn"
                    onClick={handleShow}
                    className="d-none d-md-block"
                  >
                    <img src={MenuIcon} alt="User Menu Icon" width={25} />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="subHead d-none d-md-block">
        <ul>
          <li>
            <a
              href="/sports"
              className={
                activePage == "sports" || activePage === "/" ? "active" : ""
              }
            >
              <img src={HomeIcon} alt="home" className="nactv" />
              <img src={HomeIcon3D} alt="home" className="actv" />
              <span>Home</span>
            </a>
          </li>
          <li>
            <a
              href="/sportsbook/Inplay"
              className={activePage == "sportsbook/Inplay" ? "active" : ""}
            >
              <img src={ExchangeIcon} alt="exchange" className="nactv" />
              <img src={ExchangeIcon3D} alt="exchange" className="actv" />
              <span>Exchange</span>
            </a>
          </li>
          <li>
            <a href="/betby" className={activePage == "betby" ? "active" : ""}>
              <img src={SportsbookIcon} alt="sportsbook" className="nactv" />
              <img src={SportsbookIcon3D} alt="sportsbook" className="actv" />
              <span>Sportsbook</span>
            </a>
          </li>
          <li>
            <a
              href="/live-dealer"
              className={activePage == "live-dealer" ? "active" : ""}
            >
              <img src={LiveDealerIcon} alt="live dealers" className="nactv" />
              <img src={LiveDealerIcon3D} alt="live dealers" className="actv" />
              <span>Live Dealers</span>
            </a>
          </li>
          <li>
            <a
              href="/casino/evolution"
              className={activePage == "evolution" ? "active" : ""}
            >
              <img src={GameShowIcon} alt="game shows" className="nactv" />
              <img src={GameShowIcon3D} alt="game shows" className="actv" />
              <span>Game Shows</span>
            </a>
          </li>
          <li>
            <a
              href="/casino/spribe/aviator"
              className={activePage == "aviator" ? "active" : ""}
            >
              <img src={AviatorIcon} alt="aviator" className="nactv" />
              <img src={AviatorIcon3D} alt="aviator" className="actv" />
              <span>Aviator</span>
            </a>
          </li>
          <li>
            <a
              href="/casino/spribe/mines"
              className={activePage == "mines" ? "active" : ""}
            >
              <img src={MinesIcon} alt="mines" className="nactv" />
              <img src={MinesIcon3D} alt="mines" className="actv" />
              <span>Mines</span>
            </a>
          </li>
          <li>
            <a
              href="/casino/aesexy"
              className={activePage == "aesexy" ? "active" : ""}
            >
              <img src={AeSexyIcon} alt="aesexy" className="nactv" />
              <img src={AeSexyIcon3D} alt="aesexy" className="actv" />
              <span>Ae-Sexy</span>
            </a>
          </li>
        </ul>
      </div>

      <Offcanvas
        className="user_section"
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton="closeButton" closeVariant="white">
          <Offcanvas.Title>
            <div className="d-flex">
              <div className="profile-img">
                <img src={UserIcon} alt="User Icon" />
              </div>
              <div className="user-info">
                <Link className="user_name" to="/profile">
                  {user?.mstruserid}
                </Link>
                <p>{user?.mobileno}</p>
              </div>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="container upperbalnace">
            <div className="row">
              <div className="col-6">
                <div className="balance mb-4">
                  <img src={WalletIcon} alt="wallet" />
                  Balance
                </div>
              </div>
              <div className="col-6">
                <div className="balance-amount mb-4">
                  {" "}
                  INR {wallet?.balance}
                </div>
              </div>
              {/* <div className="col-12">
                <div className="non_cash_balance">
                  <h6 className="non_cash_balance_heading">
                    <span>
                      <strong> Bonus</strong> <small>Non-Cash-Balance </small>{" "}
                    </span>
                    <span>
                      <strong>{wallet?.coins}</strong>
                    </span>
                  </h6>
                </div>
              </div> */}
              <div
                className="col-6"
                onClick={() => {
                  navigate("/withdraw");
                }}
              >
                <Button variant="" className="btn_withdraw w-100">
                  Withdraw
                </Button>
              </div>
              <div
                className="col-6"
                onClick={() => {
                  navigate("/gatewaylist");
                }}
              >
                <Button variant="primary" className="btn_primary w-100">
                  Deposit
                </Button>
              </div>
            </div>
          </div>
          <ul className="menu-items">
            {/* <li>
              <a href="/profile">
                <FaRegUserCircle />
                <span>Profile</span>
              </a>
            </li> */}
            <li>
              <a href="/mybets">
                <img src={BetIcon} alt="my bet" />
                <span>My Bets</span>
              </a>
            </li>
            <li>
              <a href="/bonus" className="img_size">
                <img src={BonusImg} />
                <span className="bonus-space">Bonus</span>
              </a>
            </li>
            <li>
              <a href="/referral" className="img_size">
                <img src={refferal} />

                <span className="bonus-space">Referral</span>
              </a>
            </li>
            <li>
              <a href="/cashier">
                <img src={AcStatement} alt="account statement" />
                <span>Account Statement</span>
              </a>
            </li>

            <li
              onClick={() => {
                dispatch(logoutUser());
              }}
            >
              <a href="#">
                {/* <LogoutIcon /> */}
                <img src={LogoutIconImg} />
                <span>Sign Out</span>
              </a>
            </li>
          </ul>

          <div className="theme-btn">
            <buton type="button" className="btn" onClick={toggleClass}>
              <img src={SunLight} alt="sun" className="light_theme" />
              <img src={MoonLight} alt="moon" className="dark_theme" />
            </buton>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      {show1 && <MobLeftbar className="d-block d-md-none" />}
    </header>
  );
};

export default HeaderAfterLogin;
